
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable_coursecategory.vue";
import AddSectorDrawer from "@/layout/header/partials/course/AddSectorDrawer.vue";
import EditSectorDrawer from "@/layout/header/partials/course/EditSectorDrawer.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "course-sector",
  components: {
    Datatable,
    AddSectorDrawer,
    EditSectorDrawer,
  },
  data() {
    return {
      componentKey: 0,
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "120px",
        },

        {
          name: "Sector Name",
          key: "sector_type",
          sortable: true,
        },
      ],
      lists: [],
      search: "",
      tableData: [],
      data: {},
    };
  },
  async created() {
    await this.getSectorData();
    Object.assign(this.tableData, this.lists);

    this.emitter.on("sector-added", async () => {
      await this.getSectorData();
      this.tableData = this.lists;
      this.componentKey += 1;
    });
  },
  methods: {
    async getSectorData() {
      await ApiService.get("configurations/sector_type/list")
        .then((response) => {
          this.lists = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    editsector(data) {
      this.emitter.emit("sector-edit-type", data);
    },

    Deletesector(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("configurations/sector_type/delete/" + `${id}`)
            .then((response) => {
              this.emitter.emit("sector-added", true);
              Swal.fire("Deleted!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
